import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { callApiAsync, callApiTextAsync } from "../../js/helper";
import { BACKEND_URL } from "../../js/defines";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteForeverOutlined, ParaglidingSharp, PersonAdd, UploadFileOutlined } from "@mui/icons-material";

function AdminSettings(props) {
  const theme = useTheme();

  const [account, setAccount] = useState(null);
  const [cfgDlgOpen, setCfgDlgOpen] = useState(null);
  const [selectedCfg, setSelectedCfg] = useState(null);
  const [files, setFiles] = useState([]);

  const [userDlgOpen, setUserDlgOpen] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");


  const [permissions, setPermissions] = useState([]);

  const [tables, setTables] = useState([]);

  const [changedPermissions, setChangedPermissions] = useState(permissions);


  const columns = [
    { 
      id: "id",
      field: 'id', 
      headerName: 'ID',
      width: 400,
    },
    {
      id: "XML_FileName",
      field: 'XML_FileName',
      headerName: 'XML_FileName',
      width: 400,
      valueGetter: (value) => value && value.length > 36 ? value.substring(36, value.length) : value
    },
    {
      id: "active",
      field: 'active',
      headerName: 'Aktiv',
      width: 400,
      renderCell: (params, row) => <Checkbox size='small' checked={params.value} onClick={() => handleOnChangePermissions(params)}/>
    },
  ];


  function handleOnChangeAccount(e, value) {
    setAccount(value);
  }

  function handleFileChange(e) {
    setFiles(e.target.files);
  }

  function handleSaveTable() {
    if(files.length > 0) {
      createTable();
    } 
  }

  function handleOnChangePermissions(value) {
    let newPerm = {
      "username": account,
      "objectId": value.id,
      "canDelete": false,
      "canUpdate": false
    }
    let newChangedPermissions = [...changedPermissions];

    let userIdx = newChangedPermissions ? newChangedPermissions.findIndex((permission) => permission[0].username == account) : -1;
    let foundIdx = newChangedPermissions[userIdx] ? newChangedPermissions[userIdx].findIndex((perm) => perm.objectId == newPerm.objectId) : -1;

    if(userIdx < 0) {
      newChangedPermissions.push([newPerm]);
    } else {
      if(foundIdx >= 0) {
        newChangedPermissions[userIdx].splice(foundIdx, 1);
      } else {
        newChangedPermissions[userIdx].push(newPerm);
      }
    }
    setChangedPermissions([...newChangedPermissions])
  }

  async function handleSavePermissions() {
    let filterdPerm = permissions ? permissions.find((permission) => permission[0].username == account) : [];
    let filterdChangedPerm = changedPermissions ? changedPermissions.find((permission) => permission[0].username == account) : [];

    //Delete old permissions
    let test = filterdPerm ? filterdPerm.map((perm, idx) => {
      deletePermission(perm);
    }) : []

    //Create new permissions
    let test2 = filterdChangedPerm ? filterdChangedPerm.map((perm, idx) => {
      createPermission(perm);
    }) : []
  }

  async function handleConfigDelete() {
    if(selectedCfg) {
      deleteTable(selectedCfg);
    }
  }


  async function handleCreateUser() {
    createUser();
  }





  async function deletePermission(perm) {
    let url = BACKEND_URL + "/api/Permissions?username=" + perm.username + "&objectId=" + perm.objectId;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    await callApiTextAsync(url, requestOptions, (response) => {
      console.log(response);
    });
  }

  async function createPermission(perm) {
    let url = BACKEND_URL + "/api/Permissions";

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Accept", "*/*");

    delete perm.id;

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(perm)
    };

    await callApiTextAsync(url, requestOptions, (response) => {
      console.log(response);
    });
  }



  async function deleteTable(oid) {
    let url = BACKEND_URL + "/api/Tables/" + oid;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    console.log(oid)

    await callApiTextAsync(url, requestOptions, (response) => {
      console.log(response);
      fetchTables();
    });
  }

  async function createTable() {
    let url = BACKEND_URL + "/api/Tables/UploadXML";

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);
    myHeaders.append("Accept", "*/*");

    let formData = new FormData();
    formData.append("XML", files[0]);

    console.log(files);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formData
    };

    await callApiTextAsync(url, requestOptions, (response) => {
      console.log(response);
      fetchTables();
      setCfgDlgOpen(false);
    });
  }

  async function createUser() {
    let url = BACKEND_URL + "/api/Auth/AddUser";

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);
    myHeaders.append("Content-Type", "application/json-patch+json");
    myHeaders.append("Accept", "*/*");

    let user = {
      "username": username,
      "password": password,
      "fullname": name,
      "email": email
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(user)
    };

    await callApiTextAsync(url, requestOptions, (response) => {
      console.log(response);
      fetchTables();
      setUserDlgOpen(false);
    });
  }

  function fetchTables() {
    let url = BACKEND_URL + "/api/Tables";

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    callApiAsync(url, requestOptions, (response) => {
      let newTables = response ? response.map((table) => {
        table["id"] = table._id.$oid;
        return table;
      }) : [];
      setTables(newTables);
      console.log(newTables);
    });
  }

  function fetchPermissions() {
    let url = BACKEND_URL + "/api/Permissions";

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    callApiAsync(url, requestOptions, (response) => {
      setPermissions(response);
      setChangedPermissions(response);
      console.log(response);
    });
  }


  useEffect(() => {
    fetchPermissions();
    fetchTables();
  }, []);

  let permissionsForAccount = permissions ? permissions.find((permission) => permission[0].username == account) : [];
  let changedPermissionsForAccount = changedPermissions ? changedPermissions.find((permission) => permission[0].username == account) : [];

  return (
    <div style={{ display: "flex", backgroundColor: theme.palette.background.default, flexDirection: "column", width: "100%", height: "calc(100svh)", overflow: "hidden" }}>
      <div style={{margin: "30px", display: "flex", flexDirection: "column", gap: "20px"}}>
        <Typography fontSize={30} fontWeight={600} color={theme.palette.text.primary}>Admin Settings</Typography>

        <div style={{display: "flex", gap: "20px"}}> 
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            fullWidth
            size="small"
            options={permissions.map((option) => option[0].username)}
            renderInput={(params) => <TextField {...params} label="Account" />}
            onChange={handleOnChangeAccount}
          />
          <IconButton onClick={() => setUserDlgOpen(true)}><PersonAdd/></IconButton>
          <IconButton onClick={() => setCfgDlgOpen(true)}><UploadFileOutlined/></IconButton>
          <IconButton style={{color: "red"}} onClick={handleConfigDelete}><DeleteForeverOutlined/></IconButton>
          <Button variant="outlined" onClick={handleSavePermissions}>SAVE</Button>
        </div>
        
        <DataGrid
          columns={columns}
          rows={tables ? tables.map((table) => {
            table["active"] = changedPermissionsForAccount ? changedPermissionsForAccount.findIndex((permission) => permission.objectId == table.id) >= 0 : false;
            return(table);
          }) : []}
          rowCount={tables.length}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
            density: "compact",
            sorting: {
              sortModel: [{ field: "XML_FileName", sort: "asc" }],
            },
          }}
          onRowClick={(params) => setSelectedCfg(params.id)}
        />

        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xs"
          open={userDlgOpen}
        >
          <DialogTitle>
            Create User
          </DialogTitle>
          <DialogContent>
            <div style={{display: "flex", flexDirection: "column", gap: "20px", padding: "10px"}}>
              <TextField size="small" label="Username" onChange={(e) => setUsername(e.target.value)}></TextField>
              <TextField size="small" label="Passwort" onChange={(e) => setPassword(e.target.value)}></TextField>
              <TextField size="small" label="Name" onChange={(e) => setName(e.target.value)}></TextField>
              <TextField size="small" label="Email" onChange={(e) => setEmail(e.target.value)}></TextField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUserDlgOpen(false)}>Abbrechen</Button>
            <Button onClick={handleCreateUser}>Erstellen</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xs"
          open={cfgDlgOpen}
        >
          <DialogTitle>
            Config Upload
          </DialogTitle>
          <DialogContent>
            <div style={{display: "flex", flexWrap: "wrap"}}>
              <input id="file" type="file" accept="text/xml" onChange={handleFileChange}/>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCfgDlgOpen(false)}>Abbrechen</Button>
            <Button onClick={handleSaveTable}>Hochladen</Button>
          </DialogActions>
        </Dialog>
      </div>  
    </div>
  );
}

export default AdminSettings;