import React, { createContext, useContext, useState } from 'react';

const TableContext = createContext();

export  const TableProvider = ({ children }) => {
    const [tableController, setTableController] = useState({
        processRowUpdateFunction: null
    });

    const setProcessRowUpdate = (func) => {
        setTableController(prevState => ({
            ...prevState,
            processRowUpdateFunction: func
        }));
    };

    return (
        <TableContext.Provider value={{ 
            tableController, 
            setProcessRowUpdate
        }}>
            {children}
        </TableContext.Provider>
    );
};
export default TableProvider;
export const useTableController = () => useContext(TableContext);
