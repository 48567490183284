import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import { HttpClient } from './HttpClient';
import ImageModule from "docxtemplater-image-module";

import test_template from '../assets/Dieing_Rechnung.docx';

const base64Regex =
    /^(?:data:)?image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;

const validBase64 =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

function base64Parser(dataURL) {
    if (
        typeof dataURL !== "string" ||
        !base64Regex.test(dataURL)
    ) {
        return false;
    }

    const stringBase64 = dataURL.replace(base64Regex, "");

    if (!validBase64.test(stringBase64)) {
        throw new Error(
            "Error parsing base64 data, your data contains invalid characters"
        );
    }

    // For nodejs, return a Buffer
    if (typeof Buffer !== "undefined" && Buffer.from) {

        return Buffer.from(stringBase64, "base64");
    }

    // For browsers, return a string (of binary content) :
    const binaryString = window.atob(stringBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }

    return bytes.buffer;
}

const imageOptions = {
    getImage(tag) {
        return base64Parser(tag);
    },
    /*getSize() {
        return [600, 848];
    }*/
    getSize() {
        return ["20.01cm", "28.75cm"];
    }
};

class FileService extends HttpClient {
    constructor() {
        super('http://188.245.34.252:9000');
    }

    async generateTestDocument(obj) {
        let fileName = 'Uploads/Reports/43196f8d-cae8-4b6d-8a9d-a7b7cccef162report.docx';

        let data = {
            data: {
                xmlData: xmlBuilder(obj.poiArray),
                myImage: obj?.image,
            },
            outputName: obj?.outputName
        };
        loadAndGenerateFile(`${this.baseUrl}/${fileName}`, data);
    }

    async generateTestDocument2(obj) {
        let fileName = 'Uploads/Reports/2cbf803d-c607-472c-9f5d-367e28238053full_image.docx'; //Uploads/Reports/a0462003-d348-46cd-af62-acd0121657c0kwwf_test.docx

        console.log(obj);

        let data = {
            data: {
                companyInfo: obj.companyInfo,
                poiData: obj.poiData,
                poiArray: obj.poiArray,
                xmlData: xmlBuilder(obj.poiArray),
                myImage: obj?.image,
            },
            outputName: obj?.outputName
        };
        loadAndGenerateFile(test_template, data);
    }

}

export const fileService = new FileService();

function loadAndGenerateFile(url, options) {
    PizZipUtils.getBinaryContent(url, function (error, content) {
        if (error) {
            throw error;
        }
        var zip = new PizZip(content);
        const expressionParser = require("docxtemplater/expressions.js");
        var doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            modules: [new ImageModule(imageOptions)],
            parser: expressionParser
        });
        doc.setData(options?.data);

        doc.renderAsync(options?.data).then(function () {
            const out = doc.getZip().generate({
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            saveAs(out, options?.outputName);
        });

        /*try {
            doc.render();
        } catch (error) {
            function replaceErrors(key, value) {
                if (value instanceof Error) {
                    return Object.getOwnPropertyNames(value).reduce(function (
                        error,
                        key
                    ) {
                        error[key] = value[key];
                        return error;
                    },
                        {});
                }
                return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors
                    .map(function (error) {
                        return error.properties.explanation;
                    })
                    .join('');
                console.log('errorMessages', errorMessages);
            }
            throw error;
        }

        var out = doc.getZip().generate({
            type: 'blob',
            mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });

        saveAs(out, options?.outputName);*/
    });
}

function escapeXml(unsafe) {
    if (typeof unsafe === "string") {
        return unsafe.replace(/[<>&'"]/g, function (c) {
            switch (c) {
                case '<': return '&lt;';
                case '>': return '&gt;';
                case '&': return '&amp;';
                case '\'': return '&apos;';
                case '"': return '&quot;';
                default:
                    return;
            }
        });
    }
    else {
        return unsafe;
    }


}

function xmlBuilder(poiArray) {

    if (!poiArray) return;
    let xmlOutput = "";
    const maxItems = Math.min(poiArray.length, poiArray.length);

    for (let index = 0; index < maxItems; index++) {
        const poiObj = poiArray[index];
        let xmlTable = `<w:p><w:pPr><w:jc w:val='center'/></w:pPr><w:r><w:rPr><w:b/></w:rPr><w:t>ID = ${poiObj?.poi?.id}</w:t></w:r></w:p>`;
        xmlTable += `<w:tbl>
        <w:tblPr>
        <w:tblStyle w:val="TableGrid"/>
        <w:tblW w:w="5000" w:type="pct"/>
        <w:tblBorders>
        <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                                <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                                <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                                <w:insideH w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                                <w:insideV w:val="single" w:sz="4" w:space="0" w:color="000000"/>
                            </w:tblBorders>
                        </w:tblPr>
                        <w:tblGrid><w:gridCol w:w="2880"/></w:tblGrid>`;

        for (const key in poiObj?.poi) {
            const element = poiObj?.poi[key];

            xmlTable += "<w:tr>";
            xmlTable += `<w:tc><w:p><w:r><w:t>${escapeXml(key)}</w:t></w:r></w:p></w:tc>`;
            xmlTable += `<w:tc><w:p><w:r><w:t>${typeof element === 'object' && element !== null ? Object.entries(element).map(([k, v]) => `${k}: ${escapeXml(v)}`).join(", ") : escapeXml(element)}</w:t></w:r></w:p></w:tc>`; xmlTable += "</w:tr>";
        }

        xmlTable += "</w:tbl>";
        xmlTable += "<w:p><w:br w:type=\"page\"/></w:p>"; // Page break after each image
        xmlOutput += xmlTable;
    }

    return xmlOutput;

}




