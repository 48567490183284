import Typography from '@mui/material/Typography';
import { Badge, IconButton, styled } from '@mui/material';
import { getSimpleLabel } from "../../js/helper";
import { Close } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import "./TabViewParentItem.css";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function TabViewParentItem(props) {
  const theme = useTheme();
  const [isHover, setIsHover] = useState(false); 
  let selectedRowsCnt = props.selectedRows && props.selectedRows[props.config.config["namespace"] + "/" + props.config.config["containerId"]] ? props.selectedRows[props.config.config["namespace"] + "/" + props.config.config["containerId"]].length : 0
  return(
    <div 
      key={props.config.config["namespace"] + "/" + props.config.config["containerId"]}
      className={props.selected ? 'invertedBorder' : 'invertedBorder selected'}
      style={{
        position: "relative",
        height: "36px",
        minWidth: "45px",
        maxWidth: props.tabSize == "small" ? "fit-content" : "calc(" + props.maxWidth + "vw)",
        display: "flex",
        gap: "4px",
        backgroundColor: props.selected || isHover ? theme.palette.background.default : theme.palette.background.paper,
        borderRadius: "16px 16px 0px 0px",
        borderColor: theme.palette.divider,
        borderStyle: "solid",
        borderWidth: "1px",
        cursor: "pointer",
        marginTop: "4px",
        paddingLeft: "10px",
        paddingRight: "6px",
        alignItems: "center",
        transition: "background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
      }}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onClick={(e) => props.onPoiSelect ? props.onPoiSelect(props.config) : null}
    >
      <StyledBadge badgeContent={selectedRowsCnt} color="primary">
        <img src={"data:image/jpeg;base64," + props.config.config["containerChooseIconBase64"]} style={{width: "20px", userSelect: "none"}}></img>
      </StyledBadge>
      <Typography noWrap style={{userSelect: "none", color: theme.palette.text.primary}}>{getSimpleLabel(props.config.config["label"])}</Typography>
      <IconButton size="small" style={{height: "24px", width: "24px"}} onClick={(e) => {
        if(props.onPoiClose) {
          props.onPoiClose(props.config)
        }
        e.stopPropagation();
      }}><Close/></IconButton>
    </div>
  );
}