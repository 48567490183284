import "../../App.css";
import { useEffect, useRef, useState } from "react";
import logo from "../../assets/WIV_Tree_Only.png";
import {
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Switch,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import {AccountCircle, AdminPanelSettings, DashboardOutlined, ImportExport, Settings} from "@mui/icons-material";
import NavBarItem from "../../widgets/navBar/NavBarItem";
import BarChartIcon from "@mui/icons-material/BarChart";
import MapView from "../mapView/MapView";
import "./MainView.css";
import { callApiAsync, createConfigFromXML, getMainUserName, toBase64 } from "../../js/helper";
import { BACKEND_URL, frontend_version } from "../../js/defines";
import WIV_Logo from "../../assets/WIV_Logo.png";
import AdminSettings from "../adminSettings/adminSettings";
import { deDE } from "@mui/material/locale";

function MainView(props) {
  let auth = btoa(props.username + ":" + props.password);

  const userSettingsTimerRef = useRef();

  const [adminDialogOpen, setAdminDialogOpen] = useState(false);
  const [adminPW, setAdminPW] = useState("");

  const [selectedIndex, setSelectedIndex] = useState(props.username === "admin" ? "admin" : "main");
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [currentDbUrl, setCurrentDbUrl] = useState("https://wiv.app.wood-in-vision.com");
  const [listsyncList, setListsyncList] = useState({});
  const [userSettings, setUserSettings] = useState({}); //Map Config - Show Icon - Show Vector
  const [updateIndex, setUpdateIndex] = useState(0); //Map Config - Show Icon - Show Vector
  const [configs, setConfigs] = useState([]);

  const [themeType, setThemeType] = useState("light");
  const theme = createTheme({
    palette: {
      mode: themeType == "dark" ? "dark" : "light",
      primary: {
        main: '#009900',
        //main: "#f07d00"
      },
      background: {
        default: themeType == "dark" ? "#333" : "#eee",
        paper: themeType == "dark" ? "#232323" : "#ddd"
      }
    },
  }, deDE);

  function onThemeChanged(themeType) {
    setThemeType(themeType);
    saveUserSetting("themeMode", themeType);
  }


  const [tabSize, setTabSize] = useState(props.userSettings && props.userSettings.tabSize ? props.userSettings.tabSize : "comfort");

  function onTabSizeChanged(tabSize) {
    setTabSize(tabSize);
    saveUserSetting("tabSize", tabSize);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [sites, setSites] = useState([
    {
      id: "main",
      name: "Dashboard",
      icon: <DashboardOutlined style={{ color: "white" }} />,
    },
    {
      id: "test",
      name: "Dashboard",
      icon: <ImportExport style={{ color: "white" }} />,
    }
  ]);

  const [functions, setFunctions] = useState([
    {
      id: "dashboard",
      name: "Dashboard",
      icon: <BarChartIcon style={{ color: "white" }} />,
      url: "https://wiv.app.wood-in-vision.com/"
    }
  ]);

  useEffect(() => {
    setFunctions([
      {
        id: "dashboard",
        name: "Dashboard",
        icon: <BarChartIcon style={{ color: "white" }} />,
        url: userSettings.grafanaUrl ?? ""
      }
    ]);
  }, [userSettings])


  function getSiteContent() {
    switch (selectedIndex) {
      case "main":
        return <MapView
            configs={configs}
            username={props.username}
            password={props.password}
            bearerToken={props.bearerToken}
            userSettings={userSettings}
            tabSize={tabSize}
            onSaveUserSetting={onSaveUserSetting}
            onSaveCompleteUserSetting={onSaveCompleteUserSetting}
          />;
      case "dashboard":
        return (
          <>
          <iframe 
            id="grafana-iframe"
            display="block"
            width="100%"
            frameBorder="0"
            padding="0"
            margin="0"
            content="width=device-width, initial-scale=1.0"
            title="Grafana"
            src={currentDbUrl}
            
          ></iframe>
          </>
        );
      case 2:
        return <p>1</p>;
      case 3:
        return <p>2</p>;
      case "admin":
        return <AdminSettings configs={configs} bearerToken={props.bearerToken}/>;
      default:
        return <MapView configs={configs} username={props.username} password={props.password} bearerToken={props.bearerToken} userSettings={userSettings}/>;

    }
  }

  function onSaveUserSetting(key, value) {
    console.log(key, value)
    saveUserSetting(key, value);
  }

  function onSaveCompleteUserSetting(newUserSettings) {
    console.log(newUserSettings)
    setUserSettings(newUserSettings);
  }

  useEffect(() => {
    getUserSettingsFromServer();
    loadCfg();

    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = '';
    });

    if(props.username === "admin") {
      sites.push(
        {
          id: "admin",
          name: "Dashboard",
          icon: <AdminPanelSettings style={{ color: "white" }} />,
        }
      )
      setSites(sites);
    }
  }, []);

  async function loadCfg() {
    let tmpConfigs = await loadFromServer();
    setConfigs(tmpConfigs);
  }

  async function getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, filterValue, stringTemplate) {
    let formdata = new FormData();
    formdata.append("url", "https://portal.wood-in-vision.com/api/v1/poi?poiUniqueId=" + poiUniqueId);
    formdata.append("systemAuthToken", auth);

    const requestOptions = {
      method: "POST",
      body: formdata
    };

    var newListItems = [];

    let url = BACKEND_URL + "/api/Fetcher/GetByBridge";
    
    if(!listTmp[poiUniqueId]) {
      await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.statusCode === 200) {
          let sortedPoiList = responseJson.data.sort(
            (a, b) => Date.parse(b.std.ts) - Date.parse(a.std.ts)
          );
          newListItems = sortedPoiList.filter((item) => filterValue(item)).map(item => { return {"key": item.std.guid/*item.poi.listsyncKey != null ? item.poi.listsyncKey : item.std.guid*/, "value": stringTemplate(item)} });
        }
      })
      .catch((error) => {
        console.error("ERROR", error);
      });
    } else {
      newListItems = listTmp[poiUniqueId];
    }

    return newListItems;
  }

  async function loadFromServer() {
    let cfgList = await loadCfgFromServer();

    if(cfgList.error) return [];

    var tmpConfigs = [];
    var listTmp = {};

    for(let cfg of cfgList) {
      if(cfg.FileUrl != null) {
        let url = BACKEND_URL + "/" + cfg.RootPath + "/" + cfg.XML_FileName;
        await fetch(url)
        .then((res) => res.text())
        .then((text) => {
          var json = createConfigFromXML(text);
          tmpConfigs.push({
            config: json,
            childs: []
          });
        })
        .catch((e) => console.error(e));
      }
    }

    //Fill listsync lists with values
    if(props.username != "admin") {
      for(let cfgIdx in tmpConfigs) {
        let cfg = tmpConfigs[cfgIdx];
        for(let rowIdx in cfg.config.rows) {
          let row = cfg.config.rows[rowIdx];
          if(row.id.includes("listsync_")) {
            let poiUniqueId = getMainUserName(props.username) + "/" + row.id.replace("listsync_", "").replace("_1", "").replace("_2", "");
            let list = [];

            if(row.id == "listsync_forest_management_plan_working_team" || 
              row.id == "listsync_forest_management_plan_sub_working_team" || 
              row.id == "listsync_forest_management_plan_nature_protection" || 
              row.id == "listsync_forest_management_plan_base_date_waldentwicklungstyp" || 
              row.id == "listsync_forest_management_plan_base_date_betriebsart" || 
              row.id == "listsync_forest_management_plan_base_date_behandlungstyp"
            ) {
              poiUniqueId = "global/" + row.id.replace("listsync_", "").replace("_1", "").replace("_2", "").replace("_betriebsart", "").replace("_waldentwicklungstyp", "").replace("_behandlungstyp", "");
            }

            if(row.id == "listsync_pgm") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.number + " " + item.poi.name))
            }

            if(row.id == "listsync_kundenstamm" || row.id == "listsync_kundenstamm_1" || row.id == "listsync_kundenstamm_2") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.company ?? "" + " " + item.poi.first_name ?? "" + " " + item.poi.last_name ?? ""))
            }

            if(row.id == "listsync_kundenstamm_fsl" || row.id == "listsync_kundenstamm_fsl_1" || row.id == "listsync_kundenstamm_fsl_2") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.key ?? "" + " " + item.poi.name ?? ""))
            }

            if(row.id == "listsync_kundenstamm_ssl" || row.id == "listsync_kundenstamm_ssl_1" || row.id == "listsync_kundenstamm_ssl_2") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.key ?? "" + " " + item.poi.name ?? ""))
            }

            if(row.id == "listsync_kundenstamm_cp" || row.id == "listsync_kundenstamm_cp_1" || row.id == "listsync_kundenstamm_cp_2") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.first_name ?? "" + " " + item.poi.last_name ?? "" + " " + item.poi.role ?? ""/*fillTemplate(cfg.config, "${role}", item)*/))
            }

            if(row.id == "listsync_contractors") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.label ?? ""))
            }


            //KWWF
            if(row.id == "listsync_forest_district") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.listsyncKey + " " + item.poi.label))
            }

            if(row.id == "listsync_forest_management_plan_district") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.department ?? ""))
            }

            if(row.id == "listsync_hunting_district") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.label))
            }


            //FE
            if(row.id == "listsync_forest_management_plan_working_team") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.key + " " + item.poi.name))
            }

            if(row.id == "listsync_forest_management_plan_sub_working_team") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.key + " " + item.poi.name))
            }

            if(row.id == "listsync_forest_management_plan_nature_protection") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => true, (item) => (item.poi.name))
            }

            if(row.id == "listsync_forest_management_plan_base_date_waldentwicklungstyp") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => (filterValue.poi.type == "10"), (item) => (item.poi.name))
            }

            if(row.id == "listsync_forest_management_plan_base_date_betriebsart") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => (filterValue.poi.type == "00"), (item) => (item.poi.name))
            }

            if(row.id == "listsync_forest_management_plan_base_date_behandlungstyp") {
              list = await getListSyncElements(poiUniqueId, tmpConfigs, listTmp, cfgIdx, rowIdx, (filterValue) => (filterValue.poi.type == "05"), (item) => (item.poi.name))
            }
            

            if(list.length > 0) {
              tmpConfigs[cfgIdx].config.rows[rowIdx].list = list;
              if(poiUniqueId != "global/forest_management_plan_base_date") {
                listTmp[poiUniqueId] = list;
                setListsyncList(listTmp);
              }
            }
          }
        }
      }
    }

    let newTmpCfg = [];
    for(let cfgIdx in tmpConfigs) {
      let foundRow = tmpConfigs[cfgIdx].config.rows.findIndex((row) => row.id.includes("guid"));
      if(foundRow >= 0) {
        let row = tmpConfigs[cfgIdx].config.rows[foundRow];
        let uniqueId = row.id.split("//")[0];
        let foundParentIdx = tmpConfigs.findIndex((cfg) => cfg.config.namespace + "/" + cfg.config.containerId == uniqueId);
        let foundParent = tmpConfigs[foundParentIdx];

        if(foundParent == null) continue;
        
        let child = tmpConfigs[cfgIdx];
        child.parentUniqueId = foundParent.config["namespace"] + "/" + foundParent.config["containerId"];
        foundParent.childs.push(child);
        if(!newTmpCfg.find((cfg) => cfg.config.namespace + "/" + cfg.config.containerId == foundParent.config.namespace + "/" + foundParent.config.containerId)) {
          newTmpCfg.push(foundParent);
        }
        tmpConfigs.slice(foundRow, 1);
        tmpConfigs.slice(foundParentIdx, 1);
      } else {
        newTmpCfg.push(tmpConfigs[cfgIdx]);
        tmpConfigs.slice(cfgIdx, 1);
      }
    }

    let newNewTmpCfgs = [];
    newTmpCfg.map((cfg) => {
      let found = newNewTmpCfgs.find((innercfg) => innercfg.config["namespace"] + "/" + innercfg.config["containerId"] == cfg.config["namespace"] + "/" + cfg.config["containerId"])
      if(!found) {
        let newChildren = [];
        cfg.childs.map((childCfg) => {
          let foundChild = newChildren.find((innercfg) => innercfg.config["namespace"] + "/" + innercfg.config["containerId"] == childCfg.config["namespace"] + "/" + childCfg.config["containerId"]);
          if(!foundChild) {
            newChildren.push(childCfg);
          }
        })
        cfg.childs = newChildren;
        newNewTmpCfgs.push(cfg);
      }
    })
    return newNewTmpCfgs;
  }

  async function loadCfgFromServer() {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    let url = BACKEND_URL + "/api/Tables";
    let resp = await fetch(url, requestOptions)
    return resp.json();
  }

  

  //User Settings Calls
  async function getUserSettingsFromServer() {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);
    let requestOptions = {
      METHOD: "GET",
      headers: myHeaders
    }
    callApiAsync(BACKEND_URL + "/api/SelectedLayers", requestOptions, (response) => {
      let newUserSettings = formatUserSettings(response);
      console.log("userSettings", newUserSettings);
      setUserSettings(newUserSettings);
      setThemeType(newUserSettings && newUserSettings.themeMode ? newUserSettings.themeMode : "light");
      setTabSize(newUserSettings&& newUserSettings.tabSize ? newUserSettings.tabSize : "comfort")
    });
  }

  function saveUserSetting(key, value) {
    let newUserSettings = {...userSettings};
    newUserSettings[key] = value;
    setUserSettings(newUserSettings);
  }

  function formatUserSettings(unformattedSettings) {
    let userSetting = unformattedSettings && unformattedSettings.length > 0 ? unformattedSettings[0] : null;
    let value = null;
    if(userSetting) {
      if(userSetting["@<current>"]) {
        if(userSetting["@<current>"]) {
          value = userSetting["@<current>"];
        }
      } else {
        value = userSetting;
      }
    }
    return value;
  }

  function getUserSetting(key) {
    let userSetting = userSettings && userSettings.length > 0 ? userSettings[0] : null;
    let value = null;
    if(userSetting) {
      if(userSetting["@<current>"]) {
        if(userSetting["@<current>"][key]) {
          value = userSetting["@<current>"][key];
        }
      } else {
        if(userSetting[key]) {
          value = userSetting[key];
        }
      }
    }
    return value;
  }

  function editUserSetting(key, value) {
    userSettings[key] = value;
    setUserSettings(userSettings);
  }

  const handleFileChange = async (e) => {
    if (e.target.files) {
      let files = e.target.files;
      editUserSetting("companyLogo", files[0] ? await toBase64(files[0]) : null);
      setUpdateIndex(updateIndex + 1);
    }
  };


  useEffect(() => {
    clearTimeout(userSettingsTimerRef.current);
    userSettingsTimerRef.current = setTimeout(() => {

      console.log("REQUEST", userSettings);

      let id = null;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + props.bearerToken);
      if(userSettings._id) {
        id = userSettings._id.$oid;
      }
      if(id) {
        let formData = new FormData();
        formData.append("jsonElement", JSON.stringify(userSettings));
        let requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: formData
        }
        callApiAsync(BACKEND_URL + "/api/SelectedLayers/" + id, requestOptions, (response) => {
          //console.log("put", response)
        });
      } else {
        let formData = new FormData();
        formData.append("data", JSON.stringify(userSettings));
        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formData,
          redirect: "follow"
        }
        callApiAsync(BACKEND_URL + "/api/SelectedLayers", requestOptions, (response) => {
          //console.log("post", response)
        });
      }
    }, 2000);
  }, [userSettings])




  //Handle Scrollbar Style
  useEffect(() => {
    document.documentElement.style.setProperty("--mui-backgroundcolor", theme.palette.background.default);
    document.documentElement.style.setProperty("--mui-backgroundpaper", theme.palette.background.paper);
    document.documentElement.style.setProperty("--mui-fontcolor", theme.palette.action.active);
    document.documentElement.style.setProperty("--mui-fontfilter", themeType == "dark" ? "invert(1)" : "");
    document.documentElement.style.setProperty("--mui-backgroundcolorhover", themeType == "dark" ? "#717171" : "#c8c8c8");
    document.documentElement.style.setProperty("--scrollbar", themeType == "dark" ? "#6b6b6b" : theme.palette.action.active);
    document.documentElement.style.setProperty("--scrollbar-hover", theme.palette.action.selected);
  }, [themeType]);

  console.log(configs)

  return (
    <ThemeProvider theme={theme}>
    {configs.length > 0 ?
    <div style={{ display: "flex", height: "100svh" }}>
      {/*NAVBAR*/}
      <div
        className="side-navbar"
        style={{ width: "70px", height: "100svh", backgroundColor: "#222222", display: "flex", flexDirection: "column" }}
      >
        <div className="div-logo" style={{ width: "70px", height: "70px" }}>
          <a>
            <img
              src={logo}
              alt="WOOD.IN.VISION Logo"
              width="60px"
              height="60px"
              style={{ margin: "6px" }}
            />
          </a>
        </div>
        <div
          className="div-navigation"
          style={{
            width: "70px",
            flex: "auto",
            overflow: "hidden",
          }}
        >
          {sites.map((site, i) => {
            return (
              <NavBarItem
                key={i}
                site={site}
                selectedIndex={site.id === selectedIndex}
                onClick={() => {
                  setSelectedIndex(site.id);
                }}
              />
            );
          })}
          <Divider orientation="horizontal" style={{backgroundColor: "#777", marginInline: "8px"}}></Divider>
          {functions.map((site, i) => {
            return (
              <NavBarItem
                key={i}
                site={site}
                selectedIndex={site.id === selectedIndex}
                onClick={() => {
                  var params = [
                    'height=' + window.screen.height,
                    'width=' + window.screen.width,
                    'fullscreen=yes'
                ].join(',');
                if(site.url != "") {
                  var popup = window.open(site.url, 'popup_window', params); 
                  popup.moveTo(0,0);
                } else {
                  alert("No Dashboard url configured");
                }
                }}
              />
            );
          })}
        </div>
        <div className="div-account" style={{ width: "70px", height: "170px" }}>
          <Tooltip title="Einstellungen" placement="right">
            <IconButton style={{ margin: "15px", color: "white" }} onClick={handleClick}>
              <Settings/>
            </IconButton>
          </Tooltip>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => setAdminDialogOpen(true)} style={{ backgroundColor: 'transparent', gap: "4px" }}>
              Administration
            </MenuItem>
            <MenuItem disableRipple style={{ backgroundColor: 'transparent', gap: "4px", cursor: "default" }}>
              Kompakte Tabs
              <Switch size="small" checked={tabSize == "small"} onClick={(e) => {
                onTabSizeChanged(e.target.checked ? "small" : "comfort");
              }}></Switch>
            </MenuItem>
            <MenuItem disableRipple style={{ backgroundColor: 'transparent', gap: "4px", cursor: "default" }}>
              Dunkler Modus
              <Switch size="small" checked={themeType == "dark"} onClick={(e) => {
                onThemeChanged(e.target.checked ? "dark" : "light");
              }}></Switch>
            </MenuItem>
            <Divider/>
            <MenuItem disableRipple style={{ backgroundColor: 'transparent', gap: "4px", cursor: "default" }}>
              <AccountCircle/>
              {props.username}
            </MenuItem>
          </Menu>
          <Tooltip title="Abmelden" placement="right">
            <IconButton
              style={{ margin: "15px", color: "white" }}
              onClick={() => setLogoutDialogOpen(true)}
            >
              <LogoutIcon/>
            </IconButton>
          </Tooltip>
          <Typography color={"white"} fontSize={12}  style={{textAlign: "center", padding: "0px", userSelect: "none"}}>v{frontend_version}</Typography>
        </div>
      </div>
      {/*CONTENT*/}
      {getSiteContent()}
      <Dialog
        open={logoutDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Abmelden</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, das Sie sich abmelden möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "grey" }}
            onClick={() => setLogoutDialogOpen(false)}
          >
            Abbrechen
          </Button>
          <Button
            variant="contained"
            onClick={() => props.onLogout()}
            autoFocus
          >
            Abmelden
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={adminDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Administration</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{padding: "20px", display: "flex", flexDirection: "column", gap: "20px"}}>
              {adminPW == "test123" ?
                <>
                  <TextField multiline rows={8} label="layerConfig" value={userSettings && userSettings.mapLayers != null ? JSON.stringify(userSettings.mapLayers) : ""} onChange={(e) => editUserSetting("mapLayers", JSON.parse(e.target.value))}></TextField>
                  <TextField multiline rows={8} label="favLayerConfig" value={userSettings && userSettings.favLayers != null ? JSON.stringify(userSettings.favLayers) : ""} onChange={(e) => editUserSetting("favLayers", JSON.parse(e.target.value))}></TextField>
                  <TextField label="grafanaUrl" value={userSettings.grafanaUrl} onChange={(e) => editUserSetting("grafanaUrl", e.target.value)}></TextField>
                  <Typography>Kundenlogo</Typography>
                  <div style={{display: "flex", flexWrap: "wrap"}}>
                    <input key={updateIndex} id="file" type="file" accept="image/png,image/jpeg,image/jpg,image/svg+xml"  onChange={handleFileChange} />
                    <img style={{width: "200px"}} key={userSettings && userSettings.companyLogo ? userSettings.companyLogo : ""} src={userSettings && userSettings.companyLogo ? userSettings.companyLogo : WIV_Logo}></img>
                  </div>
                </>
                : <TextField label="AdminPW" onChange={(e) => setAdminPW(e.target.value)}></TextField>
              }
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey" }} onClick={() => setAdminDialogOpen(false)}>Schließen</Button>
          <Button
            variant="contained"
            onClick={() => {
              saveUserSetting("administrationUsed", true);
              setAdminDialogOpen(false);
            }}
            autoFocus
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    :
    <div className="bg">
      <div className="bg-overlay"/>
      <div style={{ height: "100svh", width: "100%", top: "0px", overflow: "clip" }}>
        <div className="loading-div">
          <img src={WIV_Logo} style={{width: "100%"}}></img>
          <LinearProgress style={{width: "96%", marginLeft: "2%"}}/>
          <Typography fontWeight={600} style={{color: "#A3A397", textAlign: "center"}}>LADE DATEN</Typography>
        </div>
      </div>
    </div>    
    }
    </ThemeProvider>
  );
}

export default MainView;